.material-list-box {
  height: 400px;
  margin: 8px;
  overflow-y: scroll;
}
.material-list-box .material-item {
  border: 1px solid #f2f2f2;
  padding: 20px;
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;
}
.material-list-box .material-item .material-cover-image {
  width: 100%;
  height: 130px;
}
.material-list-box .material-item .material-title {
  height: 52px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.material-list-box .material-item .material-desc {
  height: 44px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.material-list-box .material-item .material-time {
  color: #9a9a9a;
}
.material-list-box .material-item:nth-of-type(odd) {
  margin-right: 20px;
}
.material-list-box .active {
  border: 1px solid #bebdbd !important;
}
.material-list-box .material-item:hover {
  border: 1px solid #bebdbd;
}
