.pop-list {
  padding: 0 20px;
}
.pop-list .action-top {
  margin: 16px 8px;
}
.pop-list .action-top .upload-action {
  text-align: right;
}
.pop-list .action-top .upload-action .upload-desc {
  margin-right: 10px;
  color: #999999;
  font-size: 12px;
}
.pop-list .action-top .search-box {
  text-align: right;
}
.pop-list .pop-list-body .pop-footer {
  padding: 8px;
}
.pop-list .pop-list-body .pop-footer .pop-pagination {
  text-align: right;
}
