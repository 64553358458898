.pop-list {
    padding: 0 20px;

    .action-top {
        margin: 16px 8px;

        .upload-action {
            text-align: right;

            .upload-desc {
                margin-right: 10px;
                color: #999999;
                font-size: 12px;
            }
        }

        .search-box {
            text-align: right;
        }
    }

    .pop-list-body {

        .pop-footer {
            padding: 8px;

            .pop-pagination {
                text-align: right;
            }
        }
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;