.ant-modal-body {
    padding: 0;
}

.image-group {
    height: 550px;
    padding: 24px;
    border-right: 1px solid #e6e6e6;
}

.image-list-box {
    height: 420px;
    margin: 8px;

    .image-item {
        cursor: pointer;
        position: relative;
        border-radius: 2px;
        padding: 4px;
        border: 1px solid rgba(0, 0, 0, .05);
        text-align: center;
        margin: 8px;

        .image-cover-box {

            .image-cover {
                width: 95px;
                height: 95px;
                object-fit: cover;
                margin: 0 auto;
            }
        }

        .image-file-name {
            font-size: 12px;
            margin: 0;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
        }

        .active-mask {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, .41);
            text-align: center;
            border-radius: 2px;

            .active-icon {
                font-size: 26px;
                color: #fff;
                line-height: 122px;
                text-align: center;
            }
        }
    }

    .active {
        .active-mask {
            display: block;
        }
    }

    .image-item:hover {
        border: 1px solid #333;
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;